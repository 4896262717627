import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility } from "@klickinc/kf-react-components";

const EfficacyDeepDives = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--transcript page--transcript-efficacydeepdives");
	}, []);

	return (
		<>
			<section className="transcript-section">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading">
						Transcript
					</KfHeroUtility>

					<div className="script-content">
						<div>
							<p id="part1"><span className="voice-title">Voiceover:</span> Welcome to an informative presentation from two real DMD specialists, Dr. Saunders M. Bernes and Dr. Michelle Figueroa. They're actual neurology experts and they're here to help us understand some of the science behind EMFLAZA, right here and right now.</p>
							<h2>The Science Behind EMFLAZA&reg; (deflazacort)</h2>
							<p><span className="voice-title">Voiceover:</span> Dr. Bernes, you know all about EMFLAZA, also known as deflazacort. Can you tell us a little bit about the efficacy and safety?</p>
							<p className="uppercase">Muscle strength after 3 months</p>
							<p><span className="voice-title">Dr. Bernes:</span> I believe it's important to talk about the primary outcome in the pivotal trial that led to the FDA approval. This data is from Dr. Robert Griggs and a team of researchers who evaluated 196 patients age 5 to 15 years of age with Duchenne muscular dystrophy.</p>
							<p><span className="voice-title">Dr. Bernes:</span> The team investigated the efficacy and the safety of EMFLAZA compared with placebo over three months. After the three months of treatment, EMFLAZA improved muscle strength compared to placebo.</p>
							<p><span className="uppercase">Improved muscle strength</span> after 3 months vs placebo</p>
							<p><span className="voice-title">Dr. Bernes:</span> The safety of EMFLAZA was evaluated over the year and this was a group of boys who had weakness less than five years of age. After 12 weeks of treatment, EMFLAZA at a dose of 0.9 milligrams per kilogram improved muscle strength through a MRC evaluation of 0.15 versus placebo with loss of function of 0.10.</p>
							<p><span className="voice-title">Voiceover:</span> Thank you Dr. Bernes for telling us about muscle strength and EMFLAZA.</p>
							<div className="script-blue-divider"></div>
						</div>

						<div>
							<h2 id="part2">EMFLAZA® (deflazacort) OVER TIME</h2>
							<p><span className="voice-title">Voiceover:</span> Understanding how a treatment works over the long-term can be very important. Dr. Figueroa, can you show us how EMFLAZA has worked over long periods of time?</p>
							<p><span className="voice-title">Dr. Figueroa:</span> So the study, it was done in over 20 different centers in nine different countries and in patients with Duchenne muscular dystrophy, looking at how it was progressing in patients who were aged 2- to 28-years old.</p>
							<p><span className="voice-title">Dr. Figueroa:</span> The limitations were noted, they didn't do anything to change anything, so steroid use wasn't randomized. The comparisons between deflazacort and prednisone have not been included in the approved prescribing information because prednisone is not an approved treatment for Duchenne muscular dystrophy by the FDA.</p>
							<p><span className="voice-title">Dr. Figueroa:</span> And the analyses that included the comparison of multiple corticosteroid agents noted that comparison of several of them as well as different regimens, such as daily dosing versus intermittent dosing of different corticosteroids. And an in-depth analysis of specific dosing schedules and actual doses themselves wasn't possible in the long-term observational study. And potential differences were noted in different socioeconomic status of patients as well as their caregivers. And deflazacort at that time was not commercially available in the United States.</p>
							<p className="uppercase">Followed 9 different key milestones</p>
							<p><span className="voice-title">Dr. Figueroa:</span> And through this study what we were able to say is that they followed nine different key milestones for those boys.</p>
							<p className="uppercase">Help delay dmd progression, when it matters most</p>
							<p><span className="voice-title">Dr. Figueroa:</span> And the three most important ones that were gleaned from that was coming from lying on his back to standing up and the time that that that took, how long the boys were able to walk without any assistance, their ability to maintain their hand-to-mouth function, as well as lung function over time.</p>
							<p>There were <span className="uppercase">functional benefits</span> with deflazacort</p>
							<p><span className="voice-title">Dr. Figueroa:</span> And what we were able to note is that there were functional benefits in this long-term real-world data. And what we were able to note is that deflazacort tended to allow boys to stand much more quickly and for a much longer period of time than in comparison to prednisone or no steroids. Boys were walking on average about 2.7 years longer with deflazacort and that was the ability to walk without any assistance. And they were able to maintain their hand-to-mouth function for at least about an average of 2.7 years longer with deflazacort.</p>
							<p><span className="voice-title">Voiceover:</span> Thank you for that engaging explanation of EMFLAZA over the years, Dr. Figueroa.</p>
							<div className="script-blue-divider"></div>
						</div>

						<div>
							<h2 id="part3">Age at loss of ambulation</h2>
							<p><span className="voice-title">Voiceover:</span> The relationship between treatment and age at loss of ambulation is important to understand. Dr. Bernes, can you explain the real-world data for EMFLAZA and ambulation?</p>
							<p><span className="voice-title">Dr. Bernes:</span> The first study that I would like to talk about is an observational study published by Bello and colleagues in 2015. This is a study that included over 340 patients who were at 2 to 28 years of age, were enrolled in the CINRG Duchenne Natural History study, and evaluated for loss of ambulation. They also evaluated for side effect profiles associated with different corticosteroid regimens.</p>
							<p className="uppercase">Age at loss of ambulation</p>
							<p><span className="voice-title">Dr. Bernes:</span> Loss of ambulation in this study was defined as wheelchair use or the inability to walk 10 minutes unaided.</p>
							<p><span className="voice-title">Dr. Bernes:</span> The efficacy of both deflazacort and prednisone can be seen in this graph, which is a Kaplan Meier curve. It shows the loss of ambulation for patients taking deflazacort versus those taking prednisone.</p>
							<p className="uppercase">Daily deflazacort delayed the loss of ambulation by 2.7 years</p>
							<p><span className="voice-title">Dr. Bernes:</span> The study looked at outcome for loss of ambulation, which showed that the real-world study of over 435 patients with Duchenne muscular dystrophy that daily deflazacort delayed the loss of ambulation by 2.7 years compared to a daily dose of prednisone.</p>
							<p><span className="voice-title">Voiceover:</span> That was very helpful, Dr. Bernes. Glad you could help us take a deeper look into that data.</p>
							<div className="script-blue-divider"></div>
						</div>

						<div>
							<h2 id="part4">Supporting more than just arms and legs</h2>
							<p><span className="voice-title">Voiceover:</span> EMFLAZA may help more than just muscle strength. Dr. Figueroa, can you teach us about the effects on lung function and the need for scoliosis surgery?</p>
							<p className="uppercase">Helping boys as they grow</p>
							<p><span className="voice-title">Dr. Figueroa:</span> That study that followed boys over about 11 years was the Cincinnati hospital. In its study design was that it had 430 boys that it followed over the course of about 11 to 13 years. And what was noted is that there were also benefits from deflazacort in regard to scoliosis as well as lung function.</p>
							<p className="uppercase">Delayed onset of scoliosis</p>
							<p><span className="voice-title">Dr. Figueroa:</span> And what we were able to see was that use of deflazacort itself delayed the onset of scoliosis and lowered that risk as well as delayed any time that was needed for any corrective measures or surgeries for scoliosis itself.</p>
							<p className="uppercase">Preserved lung function</p>
							<p><span className="voice-title">Dr. Figueroa:</span> Also what was noted was that it did preserve lung function in boys for a longer period of time. This was measured by what we call forced vital capacity, so it's a objective measure, and what we were noted that was almost a 10% higher forced vital capacity in boys who were using deflazacort versus prednisone.</p>
							<p><span className="voice-title">Voiceover:</span> Very interesting, Dr. Figueroa. That is some compelling data. And now, we'd like to go over the EMFLAZA Important Safety Information.</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default EfficacyDeepDives;

export function Head() {
	return (
		<Seo title="Video Transcript: Efficacy Deep Dive - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
